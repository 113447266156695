import {ReactComponent as ReactLogo} from '../assets/images/logo.svg'

const NavbarComponent = () => {
    const email = localStorage.getItem("userEmail");

    return (
        <div className="sticky-top">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                    <a href="/" className="navbar-brand">
                        <ReactLogo/>
                    </a>
                    <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto">
                            {/*<a href="/" className="nav-item nav-link">Home</a>*/}
                            <a href="/contacts" className="nav-item nav-link">Contact Us</a>


                            {email && <a className="nav-item nav-link rounded-3 active" href="/devices">Devices</a>}
                            {email && <a className="nav-item nav-link rounded-3 active" href="#"
                                         onClick={() => {
                                             localStorage.removeItem("userEmail");
                                             window.location.href = '/';
                                         }}
                            >Logout</a>}

                            {!email && <a href="/login" className="nav-item nav-link rounded-3 active">Login</a>}
                            {!email && <a href="/registration" className="nav-item nav-link rounded-3 active">Registration</a>}
                        </ul>


                    </div>
                </nav>
            </div>
        </div>
    );
}

export default NavbarComponent;