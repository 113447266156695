const FooterComponent = () => {

    return (
        <div className="container-fluid bg-dark text-white-50 footer">
            <div className="container wow fadeIn" data-wow-delay="0.1s">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; Bizcam.net, All Right Reserved.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <a href="/privacy-policy">Privacy Policy</a>
                                <a href="/terms-of-use">Terms of Use</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterComponent;