import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from 'yup';
import {useState} from "react";

const ContactSchema = Yup.object().shape({
    ip_address: Yup.string().required('This field must be filled in.'),
    port: Yup.string().required('This field must be filled in.'),
    user: Yup.string().required('This field must be filled in.'),
    password: Yup.string().required('This field must be filled in.'),
});

const ProfilePage = () => {
    const [error, setError] = useState();
    const [save, setSave] = useState(false);

    const [devices, setDevices] = useState(
        JSON.parse(localStorage.getItem("devices")) || []
    );

    const userEmail = localStorage.getItem("userEmail") || {}
    const userPassword = localStorage.getItem("userPassword") || {}

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        const {index, ...deviceData} = values;
        const updatedDevices = [...devices];
        updatedDevices[index] = deviceData;

        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
        setSave(true);
        setSave(true);
        setSubmitting(false);
    };

    const handleDeleteDevice = (index) => {
        const updatedDevices = devices.filter((_, i) => i !== index);
        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
    };

    const handleAddEmptyDevice = () => {
        if (devices.length < 4) {
            const emptyDevice = {
                ip_address: "",
                port: "",
                user: "",
                password: "",
            };
            setDevices([...devices, emptyDevice]);
        } else {
            setError("You can only add up to 4 devices.");
        }
    };

    return (
        <>
            <MetaTags>
                <title>Devices</title>
            </MetaTags>


            <div className="pt-5 bg-primary hero-header min-vh-100">
                <div className="container pt-5">

                    <div className="row align-items-center mb-4 gy-3">
                        <div className="col-12 col-lg-2">
                            <h1 className="display-4 text-white mb-4 animated slideInRight">Devices</h1>
                            <nav aria-label="breadcrumb mb-0 text-start ju">
                                <ol className="breadcrumb  mb-0">
                                    <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Devices
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="d-flex align-items-center">
                                <a href="#" className='btn btn-primary w-auto mb-0'
                                   onClick={handleAddEmptyDevice}>New device</a>

                                {save &&
                                    <div
                                        className="card-custom text-white mb-0 ms-3 p-2 px-3 text-center w-auto"
                                        role="alert">
                                        Your data has been saved
                                    </div>}

                                {error && <p className="text-danger mb-0 ms-3">
                                    {error}
                                </p>}
                            </div>
                        </div>
                    </div>

                    <div className="row g-3">
                        {devices.map((device, index) => (
                            <div className="col-12 col-lg-3" key={index}>
                                <div className="card-custom p-4">
                                    <Formik
                                        initialValues={{
                                            index: index,
                                            ip_address: device?.ip_address || '',
                                            port: device?.port || '',
                                            user: device?.user || '',
                                            password: device?.password || '',
                                        }}
                                        validationSchema={ContactSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({isSubmitting, errors, touched}) => (
                                            <Form>
                                                <Field
                                                    type="hidden"
                                                    name="index"
                                                    id="index"
                                                    value={index}
                                                />
                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="text"
                                                        name="user"
                                                        id="user"
                                                        variant="standard"
                                                        label="Username"
                                                        error={errors.user && touched.user}
                                                        helperText={errors.user && touched.user && errors.user}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="password"
                                                        variant="standard"
                                                        name="password"
                                                        id="password"
                                                        label="Password"
                                                        error={errors.password && touched.password}
                                                        helperText={errors.password && touched.password && errors.password}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="text"
                                                        name="ip_address"
                                                        variant="standard"
                                                        id="ip_address"
                                                        label="IP address"
                                                        error={errors.ip_address && touched.ip_address}
                                                        helperText={errors.ip_address && touched.ip_address && errors.ip_address}
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="text"
                                                        name="port"
                                                        id="port"
                                                        variant="standard"
                                                        label="Port"
                                                        error={errors.port && touched.port}
                                                        helperText={errors.port && touched.port && errors.port}
                                                    />
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-2">
                                                        <button className="btn btn-secondary"
                                                                onClick={() => handleDeleteDevice(index)}>
                                                            <i className="fa-regular fa-trash-can"></i>
                                                        </button>
                                                    </div>
                                                    <div className="col-10">
                                                        <button
                                                            className="btn btn-custom w-100 py-2"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            <i className="fa-solid fa-floppy-disk"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePage;