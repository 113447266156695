import MetaTags from 'react-meta-tags'

const NotFound = () => {
    return (
      <>
          <MetaTags>
              <title>404</title>
              <meta name='description'
                    content="Not Found"/>
          </MetaTags>
         <div className="container min-vh-100">
             <div className="py-5">
                 <h1>404</h1>
                 <p>Not Found</p>
             </div>
         </div>
      </>
    )
}

export default NotFound;