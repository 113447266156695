import MetaTags from "react-meta-tags";

const PrivacyPolicyPage = () => {
    return (
        <>
            <MetaTags>
                <title>BizCam | Privacy policy</title>
            </MetaTags>

            <div className="pt-5 bg-primary hero-header">
                <div className="container pt-5">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            <h1 className="display-4 text-white mb-0 animated slideInRight">Privacy policy</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section className="privacy_policy mt-5">
                <div className="container mb-5">
                    <p>This page serves to inform you about our policies governing the collection, use, and disclosure
                        of Personal Information when utilizing our Service.</p>
                    <p>We will only utilize or disclose your information as outlined in this Privacy Policy.</p>
                    <p>By using the Service, you agree to the collection and use of information as specified in this
                        Privacy Policy. Unless defined differently in this Privacy Policy, terms have the same meanings
                        as in our Terms of Use, accessible at <a
                            href="https://bizcam.net/terms-of-use/">terms of use</a>.</p>

                    <h2>Information Collection And Use</h2>

                    <p>While using our Service, we may request certain personally identifiable
                        information to contact or identify you. Personally identifiable information may include, but
                        is not limited to, your email address, name, phone number, and other information ("Personal
                        Information"). We collect this information to provide the Service, identify and communicate
                        with you, respond to your requests/inquiries, service your purchase orders, subscriptions,
                        or payments, and improve our services.
                    </p>

                    <h2>Log Data</h2>
                    <p>We may also collect information that your browser sends whenever you visit our Service ("Log
                        Data"). This Log Data may include information such as your computer's Internet Protocol
                        ("IP") address, browser type, browser version, the pages of our Service that you visit, the
                        time and date of your visit, the time spent on those pages, and other statistics.
                    </p>
                    <p>In addition, we may use third-party services such as Google Analytics that collect, monitor,
                        and analyze this type of information to enhance our Service's functionality. These
                        third-party service providers have their own privacy policies addressing how they use such
                        information.
                    </p>

                    <h2>Cookies</h2>
                    <p>Cookies are files with a small amount of data, including an anonymous unique identifier.
                        Cookies are sent to your browser from a website and transferred to your device. We use
                        cookies to collect information to improve our services for you.
                    </p>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being
                        sent. The Help feature on most browsers provides information on how to accept, disable, or
                        be notified when receiving a new cookie.
                    </p>
                    <p>If you do not accept cookies, you may not be able to use some features of our Service, and
                        we recommend leaving them turned on.
                    </p>

                    <h2>Do Not Track Disclosure</h2>

                    <p>We support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web
                        browser to inform websites that you do not want to be tracked.
                    </p>
                    <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
                        web browser.
                    </p>


                    <h2>Service Providers</h2>
                    <p>We may employ third-party companies and individuals to facilitate our Service, provide the
                        Service on our behalf, perform Service-related services, and/or assist us in analyzing how
                        our Service is used.
                    </p>
                    <p>These third parties have access to your Personal Information only to perform specific tasks
                        on our behalf and are obligated not to disclose or use your information for any other
                        purpose.
                    </p>

                    <h2>Communications</h2>
                    <p>We may ask for your permission to use your Personal Information to contact you with
                        newsletters, marketing or promotional materials, and other information that may be of
                        interest to you. You can opt out of receiving any or all of these communications from us by
                        following the unsubscribe link or instructions provided in any email we send or by
                        contacting us.
                    </p>

                    <h2>Compliance With Laws</h2>
                    <p>We will disclose your Personal Information where required by law, subpoena, or if we believe
                        that such action is necessary to comply with the law and the reasonable requests of law
                        enforcement or to protect the security or integrity of our Service.
                    </p>

                    <h2>Security</h2>
                    <p>The security of your Personal Information is important to us, and we strive to implement and
                        maintain reasonable, commercially acceptable security procedures and practices appropriate
                        to the nature of the information we store to protect it from unauthorized access,
                        destruction, use, modification, or disclosure.
                    </p>
                    <p>The connection between the user and the Service is protected with 128-bit TLS 1.2 encryption
                        (HTTPS protocol). Data is stored using our cloud services and platforms.
                    </p>
                    <p>However, please be aware that no method of transmission over the internet or electronic
                        storage is 100% secure, and we cannot guarantee the absolute security of the Personal
                        Information we have collected from you.
                    </p>
                    <p>If the information under our control is compromised due to a security breach, we will take
                        all reasonable actions to investigate the situation, notify affected individuals, and
                        address the issue in accordance with applicable laws and regulations.
                    </p>

                    <h2>International Transfer</h2>
                    <p>Your information, including Personal Information, may be transferred to and maintained on
                        computers located outside of your state, province, country, or other governmental
                        jurisdiction where data protection laws may differ from those in your jurisdiction.
                    </p>
                    <p>If you are located outside the Republic of Tanzania and choose to provide information to us,
                        please note that we transfer the information, including Personal Information, to the
                        Republic of Tanzania and the European Union and process it there.
                    </p>
                    <p>Your consent to this Privacy Policy, followed by your submission of such information,
                        represents your agreement to that transfer.
                    </p>
                    <p>Transferring information to other countries is done in accordance with the protection level
                        required by laws or legal recommendations in the Republic of Tanzania and the EU's General
                        Data Protection Regulation (GDPR) effective from 25.05.2018.
                    </p>

                    <h2>Links To Other Sites</h2>
                    <p>Our Service may contain links to other sites not operated by us. If you click on a
                        third-party link, you will be directed to that third party's site. We strongly advise you to
                        review the Privacy Policy of every site you visit.
                    </p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies, or
                        practices of any third-party sites or services.
                    </p>

                    <h2>Children's Privacy</h2>
                    <p>Only persons aged 18 or older have permission to access our Service. Our Service does not
                        address anyone under the age of 16 ("Children").
                    </p>
                    <p>We do not knowingly collect personally identifiable information from children under the age
                        of 16. If you are a parent or guardian and learn that your Children have provided us with
                        Personal Information, please contact us. If we become aware that we have collected Personal
                        Information from children under the age of 16 without verification of parental consent, we
                        will take steps to remove that information from our servers.
                    </p>

                    <h2>Error-Free Operation</h2>
                    <p>We do not guarantee error-free operation within the framework of this Privacy Policy. We may
                        not always catch an unintended privacy issue, despite our efforts to do so. We welcome your
                        comments that will help improve this Privacy Policy. We will use reasonable efforts to
                        comply with this Privacy Policy and will take prompt corrective action when we learn of any
                        failure to comply with it.
                    </p>

                    <h2>Legal Information</h2>
                    <p>This Privacy Policy is subject to the terms and conditions of our Terms of Use Agreement.
                        For a review, visit <a href="http://bizcam.net/terms-of-use/">terms of use</a>.
                        It also contains conditions relating to limitations on damages, disclaimers of warranties,
                        and dispute resolution procedures. Our Terms of Use Agreement shall take precedence over any
                        conflicting provisions of this Privacy Policy.
                    </p>

                    <h2>Changes To This Privacy Policy</h2>
                    <p>This Privacy Policy is effective as of May 25, 2018, and will remain in effect
                        except with respect to any changes in its provisions in the future, which will be in effect
                        immediately after being posted on this page.
                    </p>
                    <p>We reserve the right to update or change our Privacy Policy at any time, and you should
                        check this Privacy Policy periodically. Your continued use of the Service after we post any
                        modifications to the Privacy Policy on this page will constitute your acknowledgment of the
                        modifications and your consent to abide and be bound by the modified Privacy Policy.
                    </p>
                    <p>If we make any material changes to this Privacy Policy, we will notify you either through
                        the email address you have provided us or by placing a prominent notice on our website.
                    </p>

                    <h2>Contact Us</h2>
                    <p>For any inquiries regarding this Privacy Policy, please feel free to reach out to us:</p>
                    <p className="mb-1">
                        <strong>
                            Email
                        </strong>
                    </p>

                    <p className="mb-3">
                        <a href="mailto:support@bizcam.net">support@bizcam.net</a>
                    </p>

                    <p className="mb-2">
                        <strong>Street address</strong>
                    </p>
                    <p className="mb-0">29 Alley 466/79 Ngo Gia Tu, Group 15,</p>
                    <p className="mb-0">Duc Giang Ward,</p>
                    <p className="mb-0">Ha Noi,</p>
                    <p className="mb-0">Viet Nam</p>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicyPage;