import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from 'yup';
import {ref, string} from 'yup';
import emailjs from 'emailjs-com';
import {useState} from "react";

const RegistrationPage = () => {
    const [success, setSuccess] = useState(false);

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('This field must be filled in.'),
        password: Yup.string().required('This field must be filled in.'),
        confirmPassword: string()
            .required("Please re-type your password")
            .oneOf([ref("password")], "Passwords does not match"),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        localStorage.setItem("userEmail", values?.email);
        localStorage.setItem("password", values?.password);

        localStorage.removeItem("device_name");
        localStorage.removeItem("ip_address");
        localStorage.removeItem("port");

        emailjs
            .send(
                'service_abz83dd',
                'template_5mu6ryq',
                values,
                'LaTWr3rDMXIhQ6e3L'
            )
            .then(
                () => {
                    setSuccess(true)
                    // localStorage.setItem("userEmail", values?.email);
                    // window.location.href = '/';
                },
                (error) => {
                    console.log(error)
                }
            )
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <MetaTags>
                <title>BizCam | Registration</title>
            </MetaTags>

            <div className="container-fluid g-0">
                <div className="pt-5 bg-primary hero-header min-vh-100">
                    <div className="container pt-5">
                        <div className="row pt-5">
                            <div className="col-lg-6 align-self-center text-center text-lg-start text-center">
                                <h1 className="display-4 text-white mb-4 animated slideInRight">Registration</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                                        <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Registration</li>
                                    </ol>
                                </nav>

                                <div className="card-custom mt-5">
                                    {!success && <Formik
                                        initialValues={{email: '', password: '', confirmPassword: ''}}
                                        validationSchema={ContactSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({isSubmitting, errors, touched}) => (
                                            <Form>
                                                <div className="mb-4">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        variant="standard"
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        label="Email"
                                                        error={errors.email && touched.email}
                                                        helperText={errors.email && touched.email && errors.email}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        variant="standard"
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        label="Password"
                                                        error={errors.password && touched.password}
                                                        helperText={errors.password && touched.password && errors.password}
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        variant="standard"
                                                        type="password"
                                                        name="confirmPassword"
                                                        id="confirmPassword"
                                                        label="Confirm password"
                                                        error={errors.confirmPassword && touched.confirmPassword}
                                                        helperText={errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                                                    />
                                                </div>

                                                <button
                                                    className="btn btn-custom w-100 py-3"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "..." : "Registration"}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>}

                                    {success && <p className="py-5 text-center text-white display-4">
                                        We will assess your inquiry and respond to you shortly.
                                    </p>}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default RegistrationPage;