import MetaTags from "react-meta-tags";

const HomePage = () => {
    return (
        <>
            <MetaTags>
                <title>BizCam's Cloud Storage Solution</title>
            </MetaTags>

            <section>

                <div className="container-fluid pt-5 bg-primary hero-header hero-header-after">
                    <div className="container py-5 text-center">
                        <div className="py-5">
                            <div className="row">
                                <div className="col-12 col-lg-7 m-auto">
                                    <h1 className="display-4 text-white mb-2 animated slideInRight">
                                        The Future of Monitoring:
                                    </h1>
                                    <h1 className="display-4 text-white mb-3 animated slideInRight">
                                        Cloud Video Surveillance Solutions
                                    </h1>


                                    <p className="text-white mb-4">
                                        Delve into the evolution of video monitoring with cloud-based solutions. Uncover the advantages of remote access, real-time alerts, and scalability, making cloud video surveillance the future standard for comprehensive security
                                    </p>

                                    <a href="/" className="me-3" target="_blank">
                                        <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                    </a>

                                    <button className='p-0 bg-transparent border-0' disabled>
                                        <img src="assets/images/Google_Play_Store_badge_EN.svg"
                                             alt="Get it on Google Play"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid bg-light py-5">
                    <div className="container py-5">

                        <div className="col-lg-9 wow fadeIn" data-wow-delay="0.1s">
                            <h1 className="mb-3">
                                The Ultimate Cloud Storage Solution
                            </h1>
                            <p className="mb-5">
                                Embrace simplicity in video surveillance with our complete cloud storage solution. Regardless of your surveillance equipment's brand, our app provides a unified platform for monitoring, eliminating the need for multiple devices or intricate configurations.
                            </p>
                        </div>

                        <div className="row g-4">
                            <div className="col-12 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                                <div
                                    className="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa-solid fa-video fa-2x"></i>
                                    </div>
                                    <p>Multiuser Cloud Video Broadcasting</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                                <div
                                    className="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa-solid fa-calendar-check fa-2x"></i>
                                    </div>
                                    <p>Extended Assurance: Cloud Storage for Up to Two Years</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                                <div
                                    className="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa-solid fa-bell fa-2x"></i>

                                    </div>
                                    <p>Stay Informed: Instant Alert Notifications</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                                <div
                                    className="service-item d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa fa-shield fa-2x"></i>
                                    </div>
                                    <p>Confidence in Security: Your System, Your Privacy</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-white py-5">
                    <div className="container py-5">

                        <div className="row g-4">
                            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                                <div className="case-item position-relative overflow-hidden rounded mb-2">
                                    <div className="case-overlay text-decoration-none">
                                        <small><i className="fa-solid fa-cloud-arrow-up"></i> </small>
                                        <h3>Cloud Mastery</h3>
                                        <p className="lh-base mb-3">
                                            Take control of your surveillance setup effortlessly with Cloud Master. This title highlights the user-friendly process of creating an account and linking your CCTV cameras, making streaming a seamless experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                                <div className="case-item position-relative overflow-hidden rounded mb-2">
                                    <div className="case-overlay text-decoration-none">
                                        <small><i className="fa-solid fa-7"></i> </small>
                                        <h3>Cost-Free Confidence</h3>
                                        <p className="lh-base mb-3">
                                            Unlock the full potential of surveillance without spending a dime. Our service comes with no cost, allowing you to test and utilize all features worry-free, ensuring you get the most out of your security setup.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                                <div className="case-item position-relative overflow-hidden rounded mb-2">
                                    <div className="case-overlay text-decoration-none">
                                        <small><i className="fa-solid fa-camera-rotate"></i> </small>
                                        <h3>Universal Connectivity</h3>
                                        <p className="lh-base mb-3">
                                            Experience the freedom to connect your existing cameras to the Cloud service effortlessly. Our system ensures compatibility across a diverse range of camera brands, providing a unified surveillance solution.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid custom-block py-5">
                    <div className="container">
                        <div className="row g-5 align-items-center py-5">
                            <div className="col-lg-7 align-self-center mb-md-5 wow fadeIn"
                                 data-wow-delay="0.3s">
                                <h1 className="text-white mb-3">
                                    Transparent Excellence: Unveiling the BizCam Cloud Storage Advantage
                                </h1>
                                <p className="text-light mb-3">At BizCam Cloud Storage, there are no gimmicks-just straightforward, user-friendly solutions. Wondering why thousands choose us over competitors? We pride ourselves on avoiding deceit and complexity.</p>
                                <p className="text-light mb-0">Our technology sets the standard for user-friendliness, ensuring that everything is comprehensible and easy to manage. Coupled with affordable pricing accessible to everyone, BizCam Cloud Storage leads the market with an all-inclusive offering that excels in the price-to-quality ratio. Choose transparency, excellence, and simplicity-choose BizCam Cloud Storage.</p>
                            </div>
                            <div className="col-lg-5 align-self-end text-center text-md-end wow fadeIn"
                                 data-wow-delay="0.5s">
                                <img className="img-fluid" src="/assets/images/feature.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-light">
                    <div className="container py-10">
                        <div className="row g-3">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <img className="img-fluid" src="images/about-img.webp"/>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <h1 className="mb-3">
                                    Capture Every Moment: Uninterrupted Recordings with BizCam Cloud Storage
                                </h1>
                                <p className="mb-4">
                                    At BizCam Cloud Storage, time never stops, and neither do our recordings. Experience life in motion with continuous, 24/7 coverage, ensuring you never miss any important events. Our advanced motion detection technology marks key moments, providing easy access to archived videos.
                                </p>

                                <p className="mb-3"><strong>Take control on the go with our mobile applications:</strong></p>

                                <a href="/" className="me-3" target="_blank">
                                    <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                </a>

                                <button className='p-0 bg-transparent border-0' disabled>
                                    <img src="assets/images/Google_Play_Store_badge_EN.svg"
                                         alt="Get it on Google Play"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid bg-dark text-center">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-9 align-self-center m-auto wow fadeIn py-5"
                                 data-wow-delay="0.3s">
                                <h1 className="text-white mb-4">
                                    Recording Redefined: Anywhere, Anytime with BizCam Cloud Storage
                                </h1>
                                <p className="mb-2 text-light ">
                                    No matter where you are, BizCam Cloud Storage revolutionizes recording with simplicity. Directly record video from your BizCam cameras into the cloud—just you, the camera, and BizCam Cloud Storage are all you need for seamless recording.
                                </p>
                                <p className="mb-0 text-light ">
                                    Take control of your recording preferences—choose how you want to record video, broadcast it, or leverage other features provided by our service. With BizCam, recording is tailored to your preferences and accessible from anywhere.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomePage;