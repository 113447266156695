import MetaTags from 'react-meta-tags'

const ContactsPage = () => {
    return (
        <>
            <MetaTags>
                <title>BizCam | Contact Us</title>
            </MetaTags>

            <div className="pt-5 bg-primary hero-header min-vh-100">
                <div className="container pt-5">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            <h1 className="display-4 text-white mb-0 animated slideInRight">Contact Us</h1>
                        </div>
                    </div>

                    <div className="row gap-0">
                        <div className="col-12 col-lg-3 h-100 ">
                            <div className="card-custom text-white h-100 p-3">
                                <p><strong>Street address</strong></p>
                                <ul className="contacts_section_info mb-0">
                                    <li className="main_contact_name w-a">29 Alley 466/79 Ngo Gia Tu, Group 15,</li>
                                    <li className="main_contact_address">Duc Giang Ward,</li>
                                    <li className="main_contact_address">Ha Noi,</li>
                                    <li className="main_contact_address">Viet Nam</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 h-100 ">
                            <div className="card-custom text-white h-100 p-3">

                                <div className="blue_hdr hdr_26 bold_hdr contact_tech_name">
                                    <strong>Technical Support</strong>
                                </div>
                                <p>
                                    <a href="mailto:support@bizcam.net" className="text-white">support@bizcam.net</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactsPage;