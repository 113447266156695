import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from 'yup';
import {useState} from "react";

const LoginPage = () => {
    const [error, serError] = useState();

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('This field must be filled in.'),
        password: Yup.string().required('This field must be filled in.'),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        if ((values?.email === 'profile1@bizcam.net' && values?.password === 'NydVG6xk') ||
            (values?.email === localStorage.getItem("userEmail") && values?.password === localStorage.getItem("password"))) {
            localStorage.setItem("userEmail", values?.email);
            window.location.href = '/devices';
        } else {
            serError("Invalid credentials")
        }

        setSubmitting(false)
    };

    return (
        <>
            <MetaTags>
                <title>BizCam | Login</title>
            </MetaTags>

            <div className="container-fluid g-0">
                <div className="pt-5 bg-primary hero-header min-vh-100">
                    <div className="container pt-5">
                        <div className="row pt-5">
                            <div className="col-lg-6 align-self-center text-center text-lg-start text-center">
                                <h1 className="display-4 text-white mb-4 animated slideInRight">Login</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                                        <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                                        <li className="breadcrumb-item text-white active" aria-current="page">Login</li>
                                    </ol>
                                </nav>

                                <div className="card-custom mt-5">
                                    <Formik
                                        initialValues={{email: '', password: ''}}
                                        validationSchema={ContactSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({isSubmitting, errors, touched}) => (
                                            <Form>
                                                <div className="mb-4">
                                                    <Field
                                                        as={TextField}
                                                        variant="standard"
                                                        fullWidth
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        label="Email"
                                                        error={errors.email && touched.email}
                                                        helperText={errors.email && touched.email && errors.email}
                                                    />
                                                </div>
                                                <div className="mb-5">
                                                    <Field
                                                        as={TextField}
                                                        variant="standard"
                                                        fullWidth
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        label="Password"
                                                        error={errors.password && touched.password}
                                                        helperText={errors.password && touched.password && errors.password}
                                                    />
                                                </div>

                                                {error && <span className="text-danger">
                                                    {error}
                                                </span>}

                                                <button
                                                    className="btn btn-custom w-100 py-3"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "..." : "Login"}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;