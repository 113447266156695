import React from 'react'
import {Outlet} from 'react-router-dom'
import NavbarComponent from '../components/navbar'
import FooterComponent from '../components/footer'
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const Layout = () => {
    return (<>
        <NavbarComponent/>
        <ThemeProvider theme={darkTheme}>
            <div className="min-vh-100">
                <Outlet/>
            </div>
        </ThemeProvider>
        <FooterComponent/>
    </>)
}

export default Layout;